<template>
  <v-container fill-height class="game">
    <Keypress key-event="keypress" :key-code="50" @success="captureKey"/>
    <Keypress key-event="keypress" :key-code="52" @success="captureKey"/>
    <Keypress key-event="keypress" :key-code="53" @success="captureKey"/>
    <Keypress key-event="keypress" :key-code="54" @success="captureKey"/>
    <Keypress key-event="keypress" :key-code="56" @success="captureKey"/>
    <div class="log">
      <p>For a list of available commands, type <mud-text v="{cyan}help"/>.</p>
      <p v-for="(m, index) in messages" :key="index">
        <mud-text :v="m.output" v-if="m.output"/>
      </p>
    </div>
    <div class="input">
      <p class="prompt top"><mud-text :v="prompts.topLeft.output"/></p>
      <v-text-field light dense outlined hide-details autofocus prepend-inner-icon="fa-chevron-right" v-model="command" @keyup.enter="sendCommand()"></v-text-field>
      <p class="prompt bottom"><mud-text :v="prompts.bottomLeft.output"/></p>
    </div>
    <map-widget :map-client-string="map" v-if="showMap"/>
  </v-container>
</template>

<script>

import { VueClient } from '@/lib/VueClient'
import { CLIENT_READY } from '@webmuds/client/src/constants/client-status'

const NUMPAD_COMMANDS = new Map([
  ['Numpad2', 'south'],
  ['Numpad4', 'west'],
  ['Numpad5', 'look'],
  ['Numpad6', 'east'],
  ['Numpad8', 'north']
])

export default {
  name: 'Game',

  props: {
    mudId: Number,
    characterId: Number
  },

  client: null,

  data: () => ({
    messages: [],
    authorization: { character: {}, mud: {}, server: {} },
    command: '',
    prompts: {},
    map: null,
    showMap: true
  }),

  methods: {
    sendCommand () {
      this.$options.client.send(this.command)
      this.command = ''
    },
    captureKey (response) {
      var client = this.$options.client

      // No-ops if not connected (allows propagation and bubbling).
      if (!client.status === CLIENT_READY) return

      var event = response.event
      var command = NUMPAD_COMMANDS.get(event.code)

      if (command) {
        event.preventDefault()
        client.send(command)
      }
    }
  },

  created () {
    var client = new VueClient(this)

    client.on('message', (message) => {
      this.messages.push(message)
      client.scroll()

      // When messages update, they may change the window size.
      if (message.$events) {
        message.$events.once('updated', client.scroll, client)
      }
    })

    client.on('authorize', (payload) => {
      this.authorization = payload
    })

    client.on('room-change', (newRoom, oldRoom) => {
      if (!oldRoom || (newRoom.payload.a !== oldRoom.payload.a)) {
        this.map = client.area.strings.map
      }
    })

    client.on('status', (status) => {
      if (status !== CLIENT_READY) {
        this.map = null
      }
    })

    client.on('minimap', (visible) => {
      this.showMap = visible
    })

    client.connect(this.mudId, this.characterId)

    this.$options.client = client
    this.prompts = client.prompts
  },

  mounted () {
    this.$options.client.$element = this.$el.querySelector('.log')
  },

  components: {
    Keypress: () => import('vue-keypress'),
    MudText: () => import('@/components/MudText.vue'),
    MapWidget: () => import('@/components/MapWidget.vue')
  }
}
</script>

<style lang="scss">
.game {
  background-color: black;
  font-family: monospace, 'Courier New', Courier;

  .log {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 110px;
    padding-bottom: 10px;
    overflow-y: scroll;

    p {
      white-space: pre-wrap;
      line-height: 100%;
      margin-top: 0;
      margin-bottom: 0;
    }

  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #444;
    border-radius: 1px;
  }

  ::-webkit-scrollbar-thumb {
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 1px;
  }

  .input {
    border-top: 4px solid #777;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;

    .v-input__control {
      background-color: white;
    }

    .v-text-field__details {
      display: none;
    }

    .prompt {
      margin-bottom: 0;
    }

    .prompt.top {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .prompt.bottom {
      margin-top: 4px;
      margin-bottom: 0;
    }
  }
}
</style>
