<template>
  <game :mud-id="mudId" :character-id="characterId" v-if="ready"/>
</template>

<script>
import Game from '../components/Game'

export default {
  name: 'home',
  data () {
    return {
      ready: false
    }
  },
  computed: {
    path () {
      return this.$route.params.data || ''
    },
    parts () {
      return this.path.split('/')
    },
    mudId () {
      return parseInt(this.parts[0], 10)
    },
    characterId () {
      return parseInt(this.parts[1], 10)
    }
  },
  created () {
    if (this.mudId && this.characterId) {
      this.ready = true
      return
    }

    // TODO: (maybe?) implement mud and character selector inside client
    var url = `${process.env.VUE_APP_WEBMUDS_WEBSITE_URL}/play`
    console.log('Redirect to:', url)
    // window.location.href = url
  },
  components: { Game }
}
</script>
