'use strict'

import { Client } from '@webmuds/client/src/Client'
import { ApiClient } from '@webmuds/api-client/src/ApiClient'

const $api = new ApiClient(process.env.VUE_APP_WEBMUDS_API_URL)

export class VueClient extends Client {
  // DOM element with messages. Used for autoscrolling.
  $element = null

  // The Vue instance.
  $vue = null

  constructor ($vue) {
    super($api)
    this.$vue = $vue
    this.$scroll = VueClient.$scroll.bind(this)
    this.minimap = true
  }

  send (c) {
    if (c === 'help') {
      this.printHelp()
      return true
    }

    if (c === 'minimap') {
      this.minimap = !this.minimap
      this.emit('minimap', this.minimap)
      this.print(`The minimap is now ${this.minimap ? 'visible' : 'hidden'}. Type {cyan}minimap{/} again to toggle on/off.`)
      return true
    }

    var sent = super.send(c)
    if (sent) {
      this.scroll()
    }
    return sent
  }

  print (text) {
    var message = super.print(text)
    this.scroll()
    return message
  }

  printHelp () {
    this.print('{white}Available Commands\n\n  {cyan}help{/} - prints this message\n  {cyan}north{/}, {cyan}south{/}, {cyan}west{/}, {cyan}east{/}, {cyan}up{/}, {cyan}down{/}, {cyan}n{/}, {cyan}s, {cyan}w{/}, {cyan}e{/}, {cyan}u{/}, {cyan}d{/} - move between rooms\n  {cyan}look{/} - shows room details\n  {cyan}map{/} - prints a map of the area, if available\n  {cyan}minimap{/} - toggles mini-map on/off\n  {cyan}say{/} - sends a message to the room\n  {cyan}yell{/} - sends a message to the area\n  {cyan}global{/}, {cyan}gl{/} - sends a message to all players\n  {cyan}cozy on{/} - removes spacing ({cyan}cozy off{/} to re-enable spacing)\n  {cyan}quit{/} - quits the game\n\nIf you have a Numpad, you can use keys {lime}2{/}, {lime}4{/}, {lime}6{/}, {lime}8{/} to move {white}south{/}, {white}west{/}, {white}east{/}, and {white}north{/} respectively.')
  }

  scroll () {
    // Need to wait for DOM element to update before scrolling
    this.$vue.$nextTick(this.$scroll)

    // Accommodate for delayed message changes that may alter the window size.
    // TODO: find a better way
    // setTimeout(this.$scroll, 100)
  }

  // Scrolls immediately. Will be bound on initialization.
  static $scroll () {
    var el = this.$element

    if (!el) {
      console.warn('VueClient.scroll() called without element')
      return
    }

    el.scrollTop = el.scrollHeight
  }
}
