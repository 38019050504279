<template>
  <v-app dark>
    <v-app-bar app dense color="primary">
      <div class="d-flex align-center">
        WebMUDs Test
      </div>

      <v-spacer></v-spacer>
      ---
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'app',
  data: () => ({
    //
  })
}
</script>

<style lang="scss">
.theme--dark.v-application {
  background-color: black !important;
  color: #ccc !important;
  caret-color: white !important;
}
</style>
